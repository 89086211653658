<div id="main-wrapper">

	<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
	<app-header (found)="handleResults($event)"></app-header>
	<div class="">
		<div class="row accountPage">
			<div class="main-container searchContainerTop containerTop">
				<app-search-results [resultGridList]="data"></app-search-results>
			</div>

			<div class="profileDetail" *ngIf="model">
				<div class="row heading">
					<h3>Account Profile</h3>
				</div>

				<div class="row subHeading">
					<h5>Personal Information</h5>
				</div>

				<div class="personalInfoContainer">

					<div class="row col-md-12 pl-0 mb-2">
						<div class="col-md-4 d-flex flex-column pl-0">
							<div class="information">
								<span>Full Name</span>
							</div>
							<div class="value">
								<span>{{model.firstName}} {{model.middleName}} {{model.lastName}}</span>
							</div>
						</div>

						<div class="col-md-4 d-flex flex-column pl-0">
							<div class="information">
								<span>User Name</span>
							</div>
							<div class="value">
								<span>{{model.userName}}</span>
							</div>
						</div>

						<div class="col-md-4 d-flex flex-column pl-0">
							<div class="information">
								<span>Provider</span>
							</div>
							<div class="value">
								<span>{{model.regType}}</span>
							</div>
						</div>

					</div>

					<div class="row col-md-12 pl-0 mb-2">
						<div class="col-md-4 d-flex flex-column pl-0">
							<div class="information">
								<span>NPI</span>
							</div>
							<div class="value">
								<span>{{model.npi}}</span>
							</div>
						</div>

						<div class="col-md-4 d-flex flex-column pl-0">
							<div class="information">
								<span>Tax Id</span>
							</div>
							<div class="value">
								<span>{{model.taxId}}</span>
							</div>
						</div>

					</div>

				</div>

				<div class="row subHeading">
					<h5>Contact Information</h5>
				</div>

				<div class="contactInfoContainer mb-2">

					<div class="row col-md-12 pl-0 mb-2">

						<div class="col-md-4 d-flex flex-column pl-0">
							<div class="information">
								<span>Address</span>
							</div>
							<div class="value">
								<span>{{model.addressLine1}}{{(model.addressLine2 !== '' )? ',
									'+model.addressLine2+',':','}} {{model.city}},
									<br> {{model.state}}, {{model.zip}}
								</span>
							</div>
						</div>

						<div class="col-md-4 d-flex flex-column pl-0">
							<div class="information">
								<span>Phone</span>
							</div>
							<div class="value">
								<span>{{model.phone}}</span>
							</div>
						</div>

						<div class="col-md-4 d-flex flex-column pl-0">
							<div class="information">
								<span>Extension</span>
							</div>
							<div class="value">
								<span>{{model.extn}}</span>
							</div>
						</div>

					</div>

					<div class="row col-md-12 pl-0 mb-2">

						<div class="col-md-4 d-flex flex-column pl-0">
							<div class="information">
								<span>Email</span>
							</div>
							<div class="value">
								<span>{{model.email}}</span>
							</div>
						</div>

						<div class="col-md-4 d-flex flex-column pl-0">
							<div class="information">
								<span>Fax</span>
							</div>
							<div class="value">
								<span>{{model.fax}}</span>
							</div>
						</div>

					</div>
				</div>

				<div class="row col-md-12 pl-0">
					<div class="col-md-10 actions pl-0" align="end">
						<a class="btn btn-primary carelon-primary-btn" 
						routerLink="edit" routerLinkActive="active">Edit Profile</a>
					</div>
				</div>

			</div>
		</div>
	</div>

	<br><br><br><br>
	<app-footer ></app-footer>

</div>